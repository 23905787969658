<template>
	<nav class="hotel-nav fix-on-scroll">
		<div class="row">
			<div class="columns column12">
				<ul>
					<li>
						<nuxt-link :to="localePath(props.home)">
							{{ props.hotel.header }}
						</nuxt-link>
					</li>
					<li v-for="item in menu[locale]" :key="item.id">
						<nuxt-link :to="localePath(item.filename)" :class="{ active: item.active }">
							{{ item.header }}
						</nuxt-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script setup>
const { locale } = useI18n();

const props = defineProps({
	hotel: { type: Object, default: () => ({}) },
	home: { type: String, default: '' },
});

const { data: menu } = await useWebsiteFetch('topmenu', {
	query: { hotelID: props.hotel.pageID },
	key: `topmenu/${props.hotel.pageID}`,
});
</script>

<style lang="scss" scoped>
.hotel-nav {
	background: var(--hotel-nav-background-color);
	padding: 35px 0;
	transition: background-color 0.3s ease-in-out;

	&.no-slider {
		margin-top: 103px;
	}
}

.hotel-nav ul {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	gap: 35px;
	justify-content: center;
	letter-spacing: 1px;
	font-size: 15px;
}

.hotel-nav a {
	color: var(--header-color);

	&:hover,
	&.router-link-exact-active {
		color: var(--cta-color);
	}
}

@media (max-width: 800px) {
	.hotel-nav {
		display: none;
	}
}
</style>
